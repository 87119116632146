import { makeAutoObservable, runInAction } from "mobx"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import DashboardPageStore from "../DashboardPageStore"
import { UserRole } from "../../../../types/api/cabinet"
import { callPromisesBySettings } from "../../../../utils/service/mapper"
import { DashboardSettingsModel } from "../../../../types/api/dashboard"
import DashboardAgent from "../../../../agent/Dashboard"
import { WorkIndicatorType } from "../../types/api/common"
import { WorkIndicatorParams } from "../../types/store/common"
import {
  AnalystDashboardCounts,
  AnalystDashboardSettings,
} from "../../types/store/analystDashboard"

const countTypes = {
  newServiceContracts: "new_service_contracts_count",
  closedServiceContracts: "closed_service_contracts_count",
  newRentalContracts: "new_rental_contracts_count",
  closedRentalContracts: "closed_rental_contracts_count",
  averageRentPrice: "average_rent",
}

class AnalystDashboardStore {
  newServiceContracts: WorkIndicatorParams | null

  closedServiceContracts: WorkIndicatorParams | null

  newRentalContracts: WorkIndicatorParams | null

  closedRentalContracts: WorkIndicatorParams | null

  averageRentPrice: WorkIndicatorParams | null

  settings: AnalystDashboardSettings | null

  loader: Loader

  actionLoader: Loader

  dashboardStore: DashboardPageStore

  role: UserRole

  constructor(dashboardStore: DashboardPageStore) {
    this.dashboardStore = dashboardStore
    this.newServiceContracts = null
    this.closedServiceContracts = null
    this.newRentalContracts = null
    this.closedRentalContracts = null
    this.averageRentPrice = null
    this.settings = null
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.role = "analyst"
    makeAutoObservable(this)
  }

  initDashboard = async () => {
    this.loader.startLoading()

    await this.fetchSettings()
    await this.updateCards()

    this.loader.endLoading()
  }

  loadCards = async () => {
    this.actionLoader.startLoading()

    this.resetCards()
    await this.updateCards()

    this.actionLoader.endLoading()
  }

  updateCards = async () => {
    if (this.settings) {
      await callPromisesBySettings(this.settings, {
        newServiceContracts: this.fetchNewServiceContracts,
        closedServiceContracts: this.fetchClosedServiceContracts,
        newRentalContracts: this.fetchNewRentalContracts,
        closedRentalContracts: this.fetchClosedRentalContracts,
        averageRentPrice: this.fetchAverageRentPrice,
      })
    }
  }

  fetchSettings = async () => {
    this.loader.startLoading()

    const response = await to<DashboardSettingsModel>(
      DashboardAgent.getSettings()
    )

    resHandler(response, this.loader, this.updateSettings)
  }

  editSettings = async (settings: AnalystDashboardSettings) => {
    this.loader.startLoading()

    const body = AnalystDashboardStore.getDashboardSettingsBody(settings)
    const response = await to(DashboardAgent.editSettings(body))

    resHandler(response, this.loader, this.updateSettings, "update settings", {
      withEndLoading: false,
    })

    this.resetCards()
    await this.updateCards()

    this.loader.endLoading()
  }

  updateSettings = (settings: DashboardSettingsModel) => {
    const { visible_blocks } = settings
    this.settings = {
      newServiceContracts: !!visible_blocks.new_service_contracts_count,
      closedServiceContracts: !!visible_blocks.closed_service_contracts_count,
      newRentalContracts: !!visible_blocks.new_rental_contracts_count,
      closedRentalContracts: !!visible_blocks.closed_rental_contracts_count,
      averageRentPrice: !!visible_blocks.average_rent,
    }
  }

  fetchDashboardCount = async (type: AnalystDashboardCounts) => {
    const response = await to(
      DashboardAgent.getWorkIndicators(
        countTypes[type] as WorkIndicatorType,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this[type] = DashboardPageStore.getIndicatorParams(res)
      })
    })
  }

  fetchNewServiceContracts = async () =>
    this.fetchDashboardCount("newServiceContracts")

  fetchClosedServiceContracts = async () =>
    this.fetchDashboardCount("closedServiceContracts")

  fetchNewRentalContracts = async () =>
    this.fetchDashboardCount("newRentalContracts")

  fetchClosedRentalContracts = async () =>
    this.fetchDashboardCount("closedRentalContracts")

  fetchAverageRentPrice = async () =>
    this.fetchDashboardCount("averageRentPrice")

  resetSettings = () => {
    this.settings = null
  }

  resetCards = () => {
    this.newServiceContracts = null
    this.closedServiceContracts = null
    this.newRentalContracts = null
    this.closedRentalContracts = null
    this.averageRentPrice = null
  }

  resetDashboard = () => {
    this.resetSettings()
    this.resetCards()
  }

  static getDashboardSettingsBody = (
    settings: AnalystDashboardSettings
  ): DashboardSettingsModel => ({
    visible_blocks: {
      new_service_contracts_count: settings.newServiceContracts,
      closed_service_contracts_count: settings.closedServiceContracts,
      new_rental_contracts_count: settings.newRentalContracts,
      closed_rental_contracts_count: settings.closedRentalContracts,
      average_rent: settings.averageRentPrice,
    },
  })
}

export default AnalystDashboardStore
