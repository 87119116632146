import React from "react"
import _ from "lodash"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Button } from "kui-basic"
import { CloudDownloadIcon } from "kui-icon"
import { InspectionRoomsPhotoProps } from "./types"
import Container from "../../../../../../components/ui/Container"
import ImagesGroupWithComment from "../../../../../../components/common/ImagesGroupWithComment"
import useInspectionStore from "../../store"
import SectionTitle from "../../../../../../components/common/SectionTitle"
import { InspectionActInterface } from "../../types/store/inspection"
import TooltipByCondition from "../../../../../../components/common/TooltipByCondition"

const InspectionRoomsPhoto = (props: InspectionRoomsPhotoProps) => {
  const { editor, inspectionInfo } = useInspectionStore()
  const archiveLink = (inspectionInfo as InspectionActInterface)
    .roomsImagesArchive
  const { form } = props
  const roomsFields =
    inspectionInfo && "roomsImages" in inspectionInfo
      ? inspectionInfo.roomsImages
      : []

  const openArchive = () => {
    if (archiveLink) {
      window.open(archiveLink)
    }
  }

  return (
    <>
      <Container>
        <SectionTitle
          title="Photos"
          button={
            <TooltipByCondition
              condition={!archiveLink}
              message="The link to the archive has not been formed yet"
            >
              <Button
                size="xs"
                variant="whiteWithGray"
                endIcon={<CloudDownloadIcon />}
                onClick={openArchive}
              >
                Download all photos
              </Button>
            </TooltipByCondition>
          }
        />
      </Container>
      <StyledContainer>
        {roomsFields.map((room, index) => (
          <ImagesGroupWithComment
            label={
              room.type === "combined_bathroom"
                ? "Combined bathroom"
                : `${_.capitalize(room.type)} ${room.roomNumber || ""}`
            }
            form={form}
            name={`roomsImages.${index}`}
            disabled={!editor.isEditing}
            roomGroup={room}
          />
        ))}
      </StyledContainer>
    </>
  )
}

export default observer(InspectionRoomsPhoto)

const StyledContainer = styled(Container)`
  @media (min-width: 1200px) {
    max-width: 1392px;
  }
`
