import React from "react"
import { Box, Button, Grid } from "kui-basic"
import { DownloadIcon, TrashIcon } from "kui-icon"
import styled from "@emotion/styled"
import { PhotoSliderHeaderOptionsProps } from "./types"
import { PhotoCardParams } from "../../PhotoCard/types"

const PhotoSliderHeaderOptions = <T extends PhotoCardParams>(
  props: PhotoSliderHeaderOptionsProps<T>
) => {
  const { image, slider, onDelete, index } = props

  if (!onDelete) return null

  const handleDelete = async () => {
    const swiper = slider.current?.swiper
    onDelete(image, index)
    if (swiper?.realIndex !== 0) {
      swiper?.slidePrev()
    }
  }

  const openOriginal = async () => {
    if (image.originalImageUrl) {
      const imageResource = await fetch(image.originalImageUrl)
      const imageBlog = await imageResource.blob()
      const imageURL = URL.createObjectURL(imageBlog)

      const link = document.createElement("a")
      link.href = imageURL
      link.download = image.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <Box pr={2}>
      <Grid container>
        <Button
          size="s"
          variant="transparentWithBorder"
          endIcon={<TrashIcon />}
          onClick={handleDelete}
        >
          Delete
        </Button>
        {!!image.originalImageUrl && (
          <StyledButton
            size="s"
            variant="transparentWithBorder"
            endIcon={<DownloadIcon />}
            onClick={openOriginal}
          >
            Download
          </StyledButton>
        )}
      </Grid>
    </Box>
  )
}

export default PhotoSliderHeaderOptions

const StyledButton = styled(Button)`
  margin-left: 16px;
`
