import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import {
  DocumentModel,
  FileModel,
  DocumentVisibilityVariants,
} from "kui-crm/types"
import {
  DocumentVisibilityAPIVariants,
  FileBodyRequest,
  ImageBodyRequest,
  ImageModel,
  matchesAPIVisibilityVariants,
  uploadImage,
  uploadNewFile,
} from "kui-crm"
import { Loader } from "kui-utils"
import {
  FileParams,
  FilesStoreInterface,
  FileStoreInterface,
} from "../../types/store/file"
import { matchesVisibilityVariants } from "../../utils/content/matches"
import { FileVariant, LoadStatus } from "../../types/common"

class FileStore implements FileStoreInterface {
  id: number | null

  index?: number | null

  name: string

  url: string

  date: DateTime | null

  size: number | null

  createdBy: string

  visibility?: DocumentVisibilityVariants

  filesStore?: FilesStoreInterface | null

  extension: string

  file: File | null

  uploadStatus: LoadStatus

  uploadedData: FileBodyRequest | ImageBodyRequest | null

  loader: Loader

  constructor(file: FileParams, filesStore?: FilesStoreInterface) {
    this.id = file.id ?? null
    this.name = file.name ?? ""
    this.index = typeof file.index === "number" ? file.index : null
    this.url = file.url ?? ""
    this.date = file.date ? DateTime.fromISO(file.date) : null
    this.size = file.size ?? null
    this.filesStore = filesStore || null
    this.createdBy = file.createdBy ?? ""
    this.file = file.file || null
    this.visibility =
      file.visibility &&
      (matchesVisibilityVariants[file.visibility] as DocumentVisibilityVariants)
    this.extension = this.name.split(".")?.pop()?.toUpperCase() || ""
    this.uploadStatus = file.uploadStatus || "default"
    this.uploadedData = file.uploadedData || null
    this.loader = new Loader()
    makeAutoObservable(this, { filesStore: false })
  }

  uploadFile = async (variant: FileVariant) => {
    if (this.file) {
      this.updateUploadStatus("loading")
      const loadFunc = variant === "image" ? uploadImage : uploadNewFile
      const file = await loadFunc(this.loader, this)
      if (file) {
        this.updateUploadedData(file)
        this.updateUploadStatus("success")
      } else if (this.loader.error) {
        this.updateUploadStatus("error")
      } else this.updateUploadStatus("failure")
    }
  }

  updateUploadStatus = (status: LoadStatus) => {
    this.uploadStatus = status
  }

  updateUploadedData = (data: FileBodyRequest | ImageBodyRequest) => {
    this.uploadedData = data
  }

  editFile = (data: any) => {
    if (this.id) {
      this.filesStore?.editFile(this.id, data, () => {
        this.updateStore(data)
      })
    }
  }

  deleteFile = () => {
    if (this.id) {
      this.filesStore?.deleteFile(this.id)
    }
  }

  updateName = (name: string) => {
    this.name = name
  }

  updateStore = (data: unknown) => {}

  // get extension() {
  //   return this.name.split(".")?.pop()?.toUpperCase() || ""
  // }

  // get title() {
  //   return this.name.split(".")[0]
  // }

  setIndex = (index: number) => {
    this.index = index
  }

  get title() {
    return this.name.replace(/\.[^/.]+$/, "")
  }

  static initFromFileModel = (file: FileModel) =>
    new FileStore({
      id: file.id,
      name: file.name,
      size: Math.round((file.size / (1024 * 1024)) * 100) / 100,
      url: file.url,
    })

  static initFromDocumentModel = (file: DocumentModel) =>
    new FileStore({
      id: file.pk,
      name: file.name,
      url: file.attachment,
      size: Math.round((file.size / (1024 * 1024)) * 100) / 100,
      visibility: file.visibility,
      createdBy: file.uploaded_by,
      date: file.created,
    })

  static initFromImageModel = (file: ImageModel, name?: string) =>
    new FileStore({
      id: file.id,
      name: file.image_name || name || "",
      url: file.image_url,
      size: file.size ? Math.round((file.size / (1024 * 1024)) * 100) / 100 : 0,
    })

  static initFromFile = (file: File, index: number) =>
    new FileStore({
      name: file.name,
      size: Math.round((file.size / (1024 * 1024)) * 100) / 100,
      url: URL.createObjectURL(file),
      index,
      file,
    })

  static initFromFileStore = (file: FileStore) =>
    new FileStore({
      ...file,
      date: file.date?.toISODate(),
      visibility: file.visibility
        ? (matchesAPIVisibilityVariants[
            file.visibility
          ] as DocumentVisibilityAPIVariants)
        : "nobody",
    })
}

export default FileStore
