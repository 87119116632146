import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import to from "await-to-js"
import { resHandler } from "kui-utils"
import {
  ApartmentExpensesPeriodModel,
  CalendarStartDateModel,
} from "../types/api/expensesPeriodAPI"
import ApartmentExpensesAgent from "../../../../../agent/ApartmentExpenses"
import ApartmentExpensesStore from "./ApartmentExpenses"
import ApartmentExpensesPeriod from "./ApartmentExpensesPeriod"

class ApartmentExpensesPeriods {
  expensesStore: ApartmentExpensesStore

  periods: ApartmentExpensesPeriod[]

  selectedPeriod: ApartmentExpensesPeriod | null

  startDate: DateTime | null

  constructor(expensesStore: ApartmentExpensesStore) {
    this.periods = []
    this.selectedPeriod = null
    this.startDate = null
    this.expensesStore = expensesStore
    makeAutoObservable(this)
  }

  getPeriods = async (apartmentId: number, date: DateTime) => {
    this.expensesStore.loader.startLoading()
    this.cleanPeriods()

    await this.getStartDate(apartmentId)
    await this.getPeriodsInfo(apartmentId, date)

    this.expensesStore.loader.endLoading()
  }

  getPeriodsInfo = async (apartmentId: number, date: DateTime) => {
    const response = await to<ApartmentExpensesPeriodModel[]>(
      ApartmentExpensesAgent.getPeriods(apartmentId, date.year, date.month)
    )

    resHandler(
      response,
      this.expensesStore.loader,
      this.updatePeriodInfo,
      "fetch period status",
      { withEndLoading: true }
    )
  }

  getStartDate = async (apartmentId: number) => {
    const response = await to(ApartmentExpensesAgent.getStartDate(apartmentId))

    resHandler(response, this.expensesStore.loader, this.updateStartDate)
  }

  updateStartDate = (res: CalendarStartDateModel) => {
    this.startDate = res.date ? DateTime.fromISO(res.date) : null
  }

  updatePeriodInfo = (res: ApartmentExpensesPeriodModel[]) => {
    this.periods = res.map(
      (period) => new ApartmentExpensesPeriod(period, this.expensesStore)
    )
    this.selectedPeriod = this.periods[this.periods.length - 1] || null
  }

  setSelectedPeriod = (id: number) => {
    this.selectedPeriod =
      this.periods.find((period) => period.id === id) || null
  }

  cleanPeriods = () => {
    this.periods = []
    this.selectedPeriod = null
  }

  get availablePeriodsOptions() {
    return this.periods.map((period) => ({
      contractId: period.tenant ? period.contractInfo?.id : 0,
      number: period.contractInfo?.number,
      label: `${period.tenant ? "" : "Service "}${
        period.contractInfo?.number
      } of ${period.contractInfo?.startDate?.toFormat("dd.MM.yy")}`,
      value: period.id,
    }))
  }
}

export default ApartmentExpensesPeriods
