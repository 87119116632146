import React from "react"
import { useForm } from "react-hook-form"
import styled from "@emotion/styled"
import { DateTime } from "luxon"
import { useToggle } from "kui-utils"
import { Modal } from "kui-crm"
import UploadFilesWithSetting from "../../common/UploadFilesWithSetting"
import {
  UploadContractFilesModalProps,
  UploadFilesWithSettingsFields,
} from "./types"
import FileLoader from "../../common/FileLoader"
import { getVisibility } from "../../../utils/service/getVisibility"

function UploadFilesWithSettingsModal({
  handleSubmit,
  submitTitle,
  handleClose,
  contractType,
  required,
  ...otherProps
}: UploadContractFilesModalProps) {
  const [isLoading, startLoading, endLoading] = useToggle()
  const form = useForm<UploadFilesWithSettingsFields>({
    defaultValues: {
      files: [],
      settings: [],
    },
  })
  const filesLength = form.getValues("files").length

  const onSubmit = async (data: UploadFilesWithSettingsFields) => {
    handleClose()
    startLoading()
    console.log(data)
    const files = data.files.map((file, index) => ({
      ...file,
      ...data.settings[index],
      name: file.name,
      visibility: getVisibility(data.settings[index]),
      date: DateTime.now(),
    }))
    form.reset()
    await handleSubmit(files)
    endLoading()
  }

  return (
    <>
      <StyledModal
        title="Upload documents"
        data-testid="upload_files_modal"
        isScrollable
        handleClose={handleClose}
        {...otherProps}
      >
        <UploadFilesWithSetting
          submitTitle={submitTitle}
          required={required}
          form={form as any}
          contractType={contractType}
          handleSubmit={onSubmit}
          testId="upload_files_form"
          variant="document"
          name="files"
          withUpload={false}
        />
      </StyledModal>
      {isLoading && <FileLoader filesCount={Number(filesLength)} />}
    </>
  )
}

export default UploadFilesWithSettingsModal

const StyledModal = styled(Modal)`
  max-height: 80vh !important;
`
