import React from "react"
import { CheckboxWithController } from "kui-complex"
import { Tooltip } from "kui-crm"
import { Caption } from "kui-basic"
import styled from "@emotion/styled"
import { WarningIcon } from "kui-icon"
import { ApartmentFillingInfoProps } from "../ApartmentFillingInfo/types"
import { FillingParams } from "../ApartmentFillingCard/types"

const ApartmentFillingStatus = <T extends FillingParams>(
  props: ApartmentFillingInfoProps<T>
) => {
  const { filling, index, form, disabled } = props

  return (
    <>
      {typeof filling.check === "boolean" && (
        // @ts-ignore
        <StyledCheckbox
          name={`fillingList.${index}.check`}
          form={form as any}
          disabled={disabled}
        />
      )}
      {(filling.wasMissing || filling.isMissing) && (
        <Tooltip
          content={
            <Caption size="xs">
              The filling was missing from the last inspection
            </Caption>
          }
        >
          <StyledWarning width={17} height={17} />
        </Tooltip>
      )}
    </>
  )
}

export default ApartmentFillingStatus

const StyledWarning = styled(WarningIcon)`
  margin-left: 4px;
  margin-right: 8px;
  path {
    fill: ${({ theme }) => theme.palette.red.fiftyP};
  }
`

const StyledCheckbox = styled(CheckboxWithController)`
  margin-right: 8px;
`
