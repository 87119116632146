import React from "react"
import styled from "@emotion/styled"
import { FilledDownArrowIcon } from "kui-icon"
import {
  TableSortIconStylesProps,
  TableSortLabelProps,
  TableSortLabelStylesProps,
} from "./types"

const TableSortLabel = (props: TableSortLabelProps) => {
  const { active, direction, onClick, children } = props

  return (
    <StyledWrapper active={active} onClick={onClick}>
      {children}
      <StyledIcon direction={direction} />
    </StyledWrapper>
  )
}

export default TableSortLabel

const StyledWrapper = styled.span<TableSortLabelStylesProps>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  svg {
    opacity: ${({ active }) => (active ? 1 : 0.5)};
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`

const StyledIcon = styled(FilledDownArrowIcon)<TableSortIconStylesProps>`
  margin: 2px 4px 0 8px;
  transform: rotate(${({ direction }) => (direction === "asc" ? 180 : 0)}deg);
  transition: all ease-out 0.3s;
`
