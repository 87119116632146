import React, { useEffect } from "react"
import { useWatch } from "react-hook-form"
import {
  ApartmentHouseInputs,
  ApartmentMetersInputs,
  ApartmentSecurityInputs,
  ApartmentTelecommunicationsInputs,
} from "./fields"
import { ApartmentDescriptionFieldsProps } from "../../../../../pages/ApartmentPage/forms/description/ApartmentDescriptionForm/types"
import InputsGroupWithTitle from "../../../../common/InputsGroupWithTitle"
import { getApartmentDetailInfoFields } from "../../../../../utils/formFields/apartment"

function DescriptionFields({
  form,
  disabled,
}: ApartmentDescriptionFieldsProps) {
  const [roomsCount, bedroomsCount] = useWatch({
    control: form.control,
    name: ["roomsNumber", "bedroomsNumber"],
  })

  const detailInfoFields = getApartmentDetailInfoFields(
    roomsCount,
    bedroomsCount
  )

  useEffect(() => {
    if (Number(roomsCount) === 0 && roomsCount !== null) {
      form.setValue("bedroomsNumber", 0)
    }
  }, [roomsCount])

  return (
    <>
      <InputsGroupWithTitle
        title="Security and passwords"
        form={form}
        isEditing={!disabled}
        fields={ApartmentSecurityInputs}
      />

      <InputsGroupWithTitle
        title="Telecommunications info"
        form={form}
        isEditing={!disabled}
        fields={ApartmentTelecommunicationsInputs}
      />

      <InputsGroupWithTitle
        title="Apartment info"
        form={form}
        isEditing={!disabled}
        fields={detailInfoFields}
      />

      <InputsGroupWithTitle
        title="Houses info"
        form={form}
        isEditing={!disabled}
        fields={ApartmentHouseInputs}
      />

      <InputsGroupWithTitle
        title="Amount of metering devices"
        form={form}
        isEditing={!disabled}
        fields={ApartmentMetersInputs}
      />
    </>
  )
}

export default DescriptionFields
