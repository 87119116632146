import React from "react"
import { observer } from "mobx-react"
import { DashboardSettingsModalProps } from "./types"
import AccountantSettingsModal from "../../../variants/AccountantDashboard/AccountantSettingsModal"
import HousingManagerSettingsModal from "../../../variants/HousingManagerDashboard/HousingManagerSettingsModal"
import HousingInspectorSettingsModal from "../../../variants/HousingInspectorDashboard/HousingInspectorSettingsModal"
import SalesManagerSettingsModal from "../../../variants/SalesManagerDashboard/SalesManagerSettingsModal"
import ConsultantSettingsModal from "../../../variants/ConsultantDashboard/ConsultantSettingsModal"
import AnalystSettingsModal from "../../../variants/AnalystDashboard/AnalystSettingsModal"

const DashboardSettingsModal = (props: DashboardSettingsModalProps) => {
  const { userRole, ...modalProps } = props

  switch (userRole) {
    case "accountant":
      return <AccountantSettingsModal {...modalProps} />
    case "housing_manager":
      return <HousingManagerSettingsModal {...modalProps} />
    case "housing_inspector":
      return <HousingInspectorSettingsModal {...props} />
    case "sales_manager":
      return <SalesManagerSettingsModal {...props} />
    case "consultant":
      return <ConsultantSettingsModal {...props} />
    case "analyst":
      return <AnalystSettingsModal {...props} />
    default:
      return null
  }
}

export default observer(DashboardSettingsModal)
