import { noAccessMessage } from "./constants"

export const MeterTypeLabel = {
  T1: "One-part",
  T2: "Two-part",
  T3: "Three-part",
  cold: "Cold",
  hot: "Hot",
}

export const PaymentStatusLabel = {
  paid: "Yes",
  unpaid: "No",
  overdue: "Overdue",
}

export const InspectionEditConditionReasons = {
  already_signed: "Inspection signed and accepted",
  invoice_already_fixed_for_impairments:
    "An invoice with this expense has already been created",
  inspection_with_appraisal_or_impairments: "The inspection has attached acts",
  impairments_already_fixed: "Impairments closed",
}

export const meterUnitLabel = {
  water: "м3",
  gas: "м3",
  heating: "Гкал",
  electricity: "кВт",
}

export const checkDataLabel = {
  phone: "Phone number",
  passport: "Passport data",
  registration_address_region: "Region in actual address",
  registration_address_city: "City in place of registration",
  registration_address_street: "Street in place of registration",
  registration_address_house: "House number in place of registration",
  registration_address_flat: "Flat number in place of registration",
  registration_address_post_index: "Post index in place of registration",
}

export const closingConditionLabel = {
  have_closing_transfer_inspection: "Transfer inspection",
  have_closing_inspection_date_eq_contract_end_date: "Date of termination",
  have_closing_impairments: "Impairments inspection",
  have_last_meters_filled: "Meters&expenses data",
  have_calculated_deposit: "Deposit calculation",
  have_all_calculations_invoiced: "Confirm all invoices",
}

export const activateConditionMessage = {
  permission: noAccessMessage,
  isAutoFilled: "You need to fill out a contract",
}

export const apartmentInspectionLabel = {
  initial: "Initial",
  inventory: "Inventory",
  transfer: "Transfer",
  regular: "Regular",
  impairments: "Impairments",
  appraisal: "Appraisal",
}
