const clientDocumentPersonalFields = [
  {
    label: "Privacy policy agreement",
    name: "privacyPolicy",
  },
]

const clientDocumentCompanyFields = [
  { label: "Legal entity info", name: "companyInfo", isRequired: true },
  {
    label: "Confirmation of authority",
    name: "confirmationOfAuthority",
    isRequired: true,
  },
]

export { clientDocumentPersonalFields, clientDocumentCompanyFields }
