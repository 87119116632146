import React, { useEffect } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import useDashboardStore from "../../../store"
import AccountantDashboardCards from "../../AccountantDashboard/AccountantDashboardCards"
import HousingInspectorDashboardCards from "../../HousingInspectorDashboard/HousingInspectorDashboardCards"
import HousingManagerDashboardCards from "../../HousingManagerDashboard/HousingManagerDashboardCards"
import useTabIndex from "../../../../../hooks/useTabIndex"
import MenuPanel from "../../../../../components/ui/MenuPanel"
import { UserRole } from "../../../../../types/api/cabinet"
import SalesManagerDashboardCards from "../../SalesManagerDashboard/SalesManagerDashboardCards"
import ConsultantDashboardCards from "../../ConsultantDashboard/ConsultantDashboardCards"
import AnalystDashboardCards from "../../AnalystDashboard/AnalystDashboardCards"

const tabs = [
  "Accountant",
  "Housing manager",
  "Housing inspector",
  "Sales manager",
  "Consultant",
  "Analyst",
]

const paths = [
  `?tab=accountant`,
  `?tab=housing_manager`,
  `?tab=housing_inspector`,
  `?tab=sales_manager`,
  `?tab=consultant`,
  `?tab=analyst`,
]

const tabPanels = [
  <AccountantDashboardCards />,
  <HousingManagerDashboardCards />,
  <HousingInspectorDashboardCards />,
  <SalesManagerDashboardCards />,
  <ConsultantDashboardCards />,
  <AnalystDashboardCards />,
]

const AdminDashboardTabs = () => {
  const { adminDashboard } = useDashboardStore()
  const { setSelectedRole, dashboardStore } = adminDashboard
  const activeTab = useTabIndex(paths)

  const handleChange = (value: number) => {
    dashboardStore?.resetSettings()
    setSelectedRole(paths[value].split("=")[1] as UserRole)
  }

  useEffect(() => {
    setSelectedRole(paths[activeTab].split("=")[1] as UserRole)
  }, [])

  return (
    <StyledMenu
      activeTab={Number(activeTab)}
      paths={paths}
      tabs={tabs}
      tabPanels={tabPanels}
      label="Role"
      handleChange={handleChange}
      withContainer={false}
    />
  )
}

export default observer(AdminDashboardTabs)

const StyledMenu = styled(MenuPanel)`
  background: transparent;
  padding-bottom: 24px;
  .MenuLabel {
    font-size: 18px;
    line-height: 24px;
  }
  .KUI-ButtonTab:not(.KUI-ButtonTab_active) {
    background: transparent;
  }
`
