import React from "react"
import { observer } from "mobx-react"
import PaginationPage from "../../../../../components/common/PaginationPage"
import SortingTable from "../../../../../components/common/SortingTable"
import useRegistriesStore from "../../../store"
import InspectionsRegistryStore from "../../../store/variants/InspectionsRegistryStore"
import inspectionHeadCells from "./content"
import InspectionRegistryRow from "./InspectionRegistryRow"

const InspectionsRegistryTable = () => {
  const { activeRegistryStore } = useRegistriesStore()

  if (!activeRegistryStore) return null

  const { inspections } = activeRegistryStore as InspectionsRegistryStore

  return (
    <PaginationPage store={activeRegistryStore}>
      <SortingTable
        headParams={inspectionHeadCells}
        store={activeRegistryStore}
        offset={132}
      >
        {inspections?.map((inspection, key) => (
          <InspectionRegistryRow
            key={inspection.id}
            inspection={inspection}
            isDark={key % 2 !== 0}
          />
        ))}
      </SortingTable>
    </PaginationPage>
  )
}

export default observer(InspectionsRegistryTable)
