import { makeAutoObservable } from "mobx"
import { Loader, Paginator, resHandler, SortingFilter } from "kui-utils"
import to from "await-to-js"
import { DateTime } from "luxon"
import { InspectionParams } from "../../types/store/inspections"
import ApartmentInspectionsAgent from "../../agent/ApartmentInspections"
import {
  ApartmentInspectionListModel,
  GetApartmentInspectionsResponse,
} from "../../pages/ApartmentPage/types/api/apartmentInspectionsAPI"
import { apartmentInspectionLabel } from "../../utils/content/values"

class InspectionsListStore {
  inspections: InspectionParams[]

  loader: Loader

  paginator: Paginator

  filter: SortingFilter

  constructor() {
    this.inspections = []
    this.loader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter()
    makeAutoObservable(this)
  }

  fetchAll = async (apartmentId: number) => {
    this.loader.startLoading()

    const response = await to<GetApartmentInspectionsResponse>(
      ApartmentInspectionsAgent.getAll(
        "inspections",
        apartmentId,
        this.paginator.limit,
        this.paginator.offset,
        null,
        this.filter.filterParams
      )
    )

    resHandler(
      response,
      this.loader,
      this.updateInspections,
      "load inspections list"
    )
  }

  updateInspections = (res: GetApartmentInspectionsResponse) => {
    const mapper = (inspection: ApartmentInspectionListModel) =>
      InspectionsListStore.getInspectionParams(inspection)

    this.inspections = this.paginator.getPageResponse(
      res,
      this.inspections,
      mapper
    )
  }

  static getInspectionParams = (
    inspection: ApartmentInspectionListModel
  ): InspectionParams => ({
    id: inspection.id,
    type: inspection.inspection_type,
    date: inspection.date ? DateTime.fromISO(inspection.date) : null,
    name: `${
      apartmentInspectionLabel[
        inspection.inspection_type as keyof typeof apartmentInspectionLabel
      ]
    } inspection from ${DateTime.fromISO(inspection.date).toFormat(
      "dd.MM.yyyy"
    )}`,
  })
}

export default InspectionsListStore
