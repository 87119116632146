import React from "react"
import { Heading, Box, Grid } from "kui-basic"
import { SectionTitleProps } from "./types"

const SectionTitle = (props: SectionTitleProps) => {
  const { title, button } = props

  return (
    <Box pt={3} mb={2}>
      <Grid container alignItems="center" justify="space-between">
        <Heading size="h4">{title}</Heading>
        {button}
      </Grid>
    </Box>
  )
}

export default SectionTitle
