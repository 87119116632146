import * as yup from "yup"
import {
  defaultRequiredMessage,
  InspectionBasedOnParams,
  InspectionTypes,
  TransferInspectionTypes,
} from "kui-crm"
import { DateTime } from "luxon"

const getInspectionCommonStepSchema = (
  withRC: boolean,
  withSC: boolean,
  withBasedOnField: boolean
) =>
  yup.object().shape(
    {
      type: yup.string().required("This field is required"),
      ...(withBasedOnField
        ? {
            basedOn: yup.object({
              id: yup.number(),
              date: yup.mixed(),
              name: yup.string().required("This field is required"),
            }),
          }
        : {
            basedOn: yup
              .object({ id: yup.number(), date: yup.mixed() })
              .nullable(),
          }),
      date: yup
        .mixed()
        .required("This field is required")
        .test(
          "date",
          `Can't be less than parent inspection date`,
          function (startDate) {
            if (!this.parent.basedOn.date) return true
            return (
              Number(
                (startDate as DateTime)
                  ?.diff(this.parent.basedOn.date, "days")
                  .toObject().days
              ) >= 0
            )
          }
        ),
      clientRole: yup.string().when(["type", "basedOn"], {
        is: (type: InspectionTypes, basedOn: InspectionBasedOnParams) =>
          type === "appraisal" && basedOn?.id,
        then: (rule) => rule.required(defaultRequiredMessage),
      }),
      transferType: yup.string().when("type", {
        is: (value: InspectionTypes) => value === "transfer",
        then: (rule) => rule.required(defaultRequiredMessage),
      }),
      contractFile: yup
        .object({
          file: yup.mixed(),
        })
        .when("type", {
          is: (value: InspectionTypes) => value === "inventory" && !withSC,
          then: (rule) =>
            rule.shape({
              file: yup.mixed().required(defaultRequiredMessage),
            }),
        })
        .when("transferType", {
          is: (value: TransferInspectionTypes) =>
            value === "maroom_tenant" && !withRC,
          then: (rule) =>
            rule.shape({
              file: yup.mixed().required(defaultRequiredMessage),
            }),
        }),
    },
    [["date", "date"]]
  )

export default getInspectionCommonStepSchema
