import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Grid, Heading } from "kui-basic"
import styled from "@emotion/styled"
import { useLocation, useNavigate } from "react-router-dom"
import { Routes } from "../../types/route"
import RegistrySearchPanel from "./components/common/RegistrySearchPanel"
import useRegistriesStore from "./store"
import InsuranceRegistryTable from "./components/tables/InsuranceRegistryTable"
import ProxyRegistryTable from "./components/tables/ProxyRegistryTable"
import ExpensesRegistryTable from "./components/tables/ServicesRegistryTable"
import InspectionsRegistryTable from "./components/tables/InspectionsRegistryTable"
import MetersReadingsRegistryTable from "./components/tables/MetersReadingsRegistryTable"
import Container from "../../components/ui/Container"
import ButtonSelect from "../../components/ui/ButtonSelect"
import TenantAppraisalsRegistryTable from "./components/tables/TenantAppraisalsRegistryTable"
import LandlordAppraisalsRegistryTable from "./components/tables/LandlordAppraisalsRegistryTable"
import ImpairmentsRegistryTable from "./components/tables/ImpairmentsRegistryTable"
import Page from "../../components/common/Page"

const tabs = [
  "Expenses",
  "Inspections",
  "Tenant appraisals",
  "Landlord appraisals",
  "Impairments",
  "Insurances",
  "Proxies",
  "Meter verifications",
  // "Documents",
  // "Payments",
]
const tabPanels = [
  <ExpensesRegistryTable />,
  <InspectionsRegistryTable />,
  <TenantAppraisalsRegistryTable />,
  <LandlordAppraisalsRegistryTable />,
  <ImpairmentsRegistryTable />,
  <InsuranceRegistryTable />,
  <ProxyRegistryTable />,
  <MetersReadingsRegistryTable />,
  // <div />,
  // <div />,
]

const paths = [
  Routes.expensesRegistry,
  Routes.inspectionsRegistry,
  Routes.tenantAppraisalsRegistry,
  Routes.landlordAppraisalsRegistry,
  Routes.impairmentsRegistry,
  Routes.insurancesRegistry,
  Routes.proxiesRegistry,
  Routes.metersVerificationsRegistry,
  // Routes.documentsRegistry,
  // Routes.paymentsRegistry,
]

const RegistriesPage = () => {
  const { setActiveRegistry, clear } = useRegistriesStore()
  const location = useLocation().pathname
  const activeTab = Math.max(
    paths.findIndex((name) => name === location),
    0
  )
  const [currentTab, setCurrentTab] = useState(Number(activeTab))
  const navigate = useNavigate()
  const registriesOptions = tabs.map((label, value) => ({ label, value }))

  const handleTabChange = (tabIndex: number) => {
    setActiveRegistry(tabIndex)
    setCurrentTab(tabIndex)
    navigate(paths[tabIndex])
  }

  useEffect(() => clear, [])

  useEffect(() => {
    setActiveRegistry(activeTab)
    setCurrentTab(activeTab)
  }, [activeTab])

  return (
    <Page withContainer={false}>
      <StyledContainer>
        <StyledWrapper container alignItems="center" justify="space-between">
          <StyledHeadingWrapper container alignItems="center">
            <StyledHeading size="h3">Registry</StyledHeading>
            <StyledSelect
              selectedValue={currentTab}
              iconProps={{ width: 10, height: 6 }}
              options={registriesOptions}
              handleChange={handleTabChange}
            />
          </StyledHeadingWrapper>
          <Grid item>
            <RegistrySearchPanel activeTab={currentTab} />
          </Grid>
        </StyledWrapper>
      </StyledContainer>
      {tabPanels[currentTab]}
    </Page>
  )
}

export default observer(RegistriesPage)

const StyledHeading = styled(Heading)`
  margin-right: 24px;
`

const StyledContainer = styled(Container)`
  position: sticky;
  top: 64px;
  z-index: 11;
  background: white;
`

const StyledHeadingWrapper = styled(Grid)`
  width: fit-content;
`

const StyledWrapper = styled(Grid)`
  padding: 8px 0 20px 0;
  flex-wrap: nowrap;
`

const StyledSelect = styled(ButtonSelect)`
  > p,
  input {
    color: ${({ theme }) => theme.palette.grey.seventy};
    font-weight: 600;
    font-size: 14px !important;
  }
`
