import * as yup from "yup"
import { numberRegExp } from "../../../../utils/content/regexp"

const CompanyBankInfoSchema = yup.object().shape({
  name: yup.string().required("Enter the bank name"),
  bankName: yup.string().required("Enter the bank entity"),
  SWIFT: yup
    .string()
    .min(8, "The SWIFT code must be at least 8 characters long")
    .max(11, "The SWIFT code must be no more than 11 characters")
    .nullable(),
  BIC: yup
    .string()
    .min(8, "Must be at least 8 characters")
    .max(10, "Must be no more 10 characters")
    .matches(numberRegExp, "Only numbers are allowed for input")
    .required("This field is required"),
  correspondentAccount: yup
    .string()
    .matches(numberRegExp, "Only numbers are allowed for input")
    .min(20, "There must be 20 characters")
    .max(20, "There must be 20 characters")
    .required("Enter the bank account"),
  account: yup
    .string()
    .matches(numberRegExp, "Only numbers are allowed for input")
    .min(20, "There must be 20 characters")
    .max(20, "There must be 20 characters")
    .required("Enter the bank account"),
})

const CompanyBankInfoFormSchema = yup.object().shape({
  banks: yup.array().of(CompanyBankInfoSchema),
})

export default CompanyBankInfoFormSchema
