import React from "react"
import { observer } from "mobx-react"
import useDashboardStore from "../../store"
import AnalystDashboardCards from "./AnalystDashboardCards"
import DashboardPageWrapper from "../../components/common/DashboardPageWrapper"

const AnalystDashboard = () => {
  const { analystDashboard } = useDashboardStore()

  return (
    <DashboardPageWrapper dashboardStore={analystDashboard}>
      <AnalystDashboardCards />
    </DashboardPageWrapper>
  )
}

export default observer(AnalystDashboard)
