import React from "react"
import { HomeIcon, PersonIcon, RubleIcon } from "kui-icon"
import { addToArrayByCondition } from "kui-utils"
import { DashboardNumberCardProps } from "../../../components/common/DasboardNumberCard/types"
import AnalystDashboardStore from "../../../store/variants/AnalystDashboardStore"

export const getServiceContractsNumberCards = (
  analystDashboard: AnalystDashboardStore
): DashboardNumberCardProps[] => [
  ...addToArrayByCondition(analystDashboard.newServiceContracts !== null, {
    label: "New service contracts",
    icon: <HomeIcon />,
    ...analystDashboard.newServiceContracts,
  }),
  ...addToArrayByCondition(analystDashboard.closedServiceContracts !== null, {
    label: "Closed service contracts",
    icon: <HomeIcon />,
    ...analystDashboard.closedServiceContracts,
  }),
]

export const getRentalContractsNumberCards = (
  analystDashboard: AnalystDashboardStore
): DashboardNumberCardProps[] => [
  ...addToArrayByCondition(analystDashboard.newRentalContracts !== null, {
    label: "New rental contracts",
    icon: <PersonIcon />,
    ...analystDashboard.newRentalContracts,
  }),
  ...addToArrayByCondition(analystDashboard.closedRentalContracts !== null, {
    label: "Closed rental contracts",
    icon: <PersonIcon />,
    ...analystDashboard.closedRentalContracts,
  }),
]

export const getKPINumberCards = (
  analystDashboard: AnalystDashboardStore
): DashboardNumberCardProps[] => [
  ...addToArrayByCondition(analystDashboard.averageRentPrice !== null, {
    label: "Average rent",
    icon: <RubleIcon />,
    unit: "₽",
    ...analystDashboard.averageRentPrice,
  }),
]

export const getServiceContractsCards = (
  analystDashboard: AnalystDashboardStore
) => ({
  numberCards: getServiceContractsNumberCards(analystDashboard),
})

export const getRentalContractsCards = (
  analystDashboard: AnalystDashboardStore
) => ({
  numberCards: getRentalContractsNumberCards(analystDashboard),
})

export const getKPICards = (analystDashboard: AnalystDashboardStore) => ({
  numberCards: getKPINumberCards(analystDashboard),
})
