import { makeAutoObservable } from "mobx"
import { CatalogItemParams } from "kui-crm"
import { Loader, Paginator, resHandler, SortingFilter } from "kui-utils"
import to from "await-to-js"
import CompanyAgent from "../../agent/Company"
import { GetCompanyBankInfoResponse } from "../../pages/CompanyPage/types/api/companyAPI"

class CompanyBankAccountsStore {
  bankAccounts: CatalogItemParams[]

  loader: Loader

  filter: SortingFilter

  paginator: Paginator

  constructor() {
    this.bankAccounts = []
    this.loader = new Loader()
    this.filter = new SortingFilter()
    this.paginator = new Paginator()
    makeAutoObservable(this)
  }

  fetchAll = async (id: number) => {
    this.loader.startLoading()

    const response = await to<GetCompanyBankInfoResponse>(
      CompanyAgent.getCompanyBanks(id, this.filter.filterParams)
    )

    resHandler(response, this.loader, this.updateBankAccounts)
  }

  updateBankAccounts = (accounts: GetCompanyBankInfoResponse) => {
    this.bankAccounts = accounts.map((account) => ({
      id: account.id,
      name: account.name,
    }))
  }
}

export default CompanyBankAccountsStore
