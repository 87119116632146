import React, { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid, Box } from "kui-basic"
import { InputByType, FormBottom, LoaderState } from "kui-crm"
import { Loader } from "kui-utils"
import { observer } from "mobx-react"
import { ClientPersonalStepSchema, ClientCompanyStepSchema } from "./schema"
import { ClientInfoStepProps, ClientPersonalFormFields } from "./types"
import { clientPersonalStepFields, clientCompanyStepFields } from "./fields"
import { InputParams } from "../../../common/InputsGroupWithTitle/types"
import { ClientCreationFields } from "../../../modals/ClientCreationModal/types"
import ClientsLiteStore from "../../../../store/lites/ClientsLiteStore"

const ClientTypeVariants = {
  personal: {
    fields: clientPersonalStepFields,
    schema: ClientPersonalStepSchema,
  },
  company: {
    fields: clientCompanyStepFields,
    schema: ClientCompanyStepSchema,
  },
}

const ClientInfoStep = ({ formStore }: ClientInfoStepProps) => {
  const clientType = formStore.fields?.type || "personal"
  const clientParams = ClientTypeVariants[clientType]
  const loaderRef = useRef(new Loader())
  const loader = loaderRef.current
  const form = useForm<ClientPersonalFormFields>({
    defaultValues: { ...formStore.fields },
    resolver: yupResolver(clientParams.schema),
  })
  const phone = form.watch("phone")
  const hasErrors = !!Object.keys(form.control._formState.errors).length

  const handleSubmit = (data: ClientPersonalFormFields) => {
    const { updateFormFields, nextStep } = formStore

    updateFormFields(data)
    nextStep()
  }

  const checkPhoneNumber = async () => {
    if (phone && !phone?.phoneNumber.includes("_")) {
      form.clearErrors("phone.phoneNumber")

      const isUnique = await ClientsLiteStore.checkPhoneNumber(phone, loader)

      if (typeof isUnique === "boolean" && !isUnique) {
        form.setError("phone.phoneNumber", {
          type: "manual",
          message: "This phone number is already in use",
        })
      }
    } else {
      form.clearErrors("phone.phoneNumber")
    }
  }

  useEffect(() => {
    if (phone && phone?.phoneNumber) checkPhoneNumber()
    else form.clearErrors("phone.phoneNumber")
  }, [phone?.phoneCode, phone?.phoneNumber])

  return (
    <form data-testid="create_user_form">
      <Box pr={5} pl={5} mb={3}>
        <Grid container spacing={2}>
          {clientParams.fields.map(
            (field: InputParams<ClientCreationFields>) => (
              <Grid item xs={12} key={field.name}>
                <InputByType form={form} {...field} />
              </Grid>
            )
          )}
        </Grid>
      </Box>
      <FormBottom
        withPrevStep
        label="Next"
        variant="next"
        handlePrevClick={formStore.prevStep}
        type="button"
        handleClick={form.handleSubmit(handleSubmit)}
        isLoading={loader.isLoading}
        disabled={hasErrors || loader.isLoading}
      />

      <LoaderState loader={loader} onlyError />
    </form>
  )
}

export default observer(ClientInfoStep)
