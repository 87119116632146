import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import {
  CabinetUserModel,
  UserPermissions,
  UserRole,
} from "../../types/api/cabinet"
import CabinetAgent from "../../agent/Cabinet"
import RootStore from "../Root"
import { AccessPermissionActions } from "../../types/store/appStore"
import { userPermissions } from "../../utils/content/matches"

class CabinetStore {
  userId: number | null

  rootStore: RootStore

  fullName: string

  role: UserRole | null

  accessPermissions: AccessPermissionActions[]

  loader: Loader

  actionLoader: Loader

  constructor(rootStore: RootStore) {
    this.userId = null
    this.rootStore = rootStore
    this.fullName = ""
    this.role = null
    this.accessPermissions = []
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    makeAutoObservable(this, { rootStore: false })
  }

  getUser = async () => {
    this.loader.startLoading()
    const response = await to<CabinetUserModel>(CabinetAgent.getUser())

    resHandler(response, this.loader, this.updateUser, "get user info")
  }

  updateUser = (res: CabinetUserModel) => {
    this.userId = res.id
    this.fullName = `${res.first_name ?? ""} ${res.last_name ?? ""}`
    this.role = res.user_role
    this.accessPermissions =
      res.permissions
        ?.map((permission) => CabinetStore.getDetailedPermission(permission))
        .flat() || []
  }

  updateAccessPermissions = (data: AccessPermissionActions[]) => {
    this.accessPermissions = data
  }

  isThereAccess = (permission: AccessPermissionActions) => {
    if (!this.accessPermissions.length) return true

    return this.accessPermissions.includes(permission)
  }

  reset = () => {
    this.accessPermissions = []
  }

  static getDetailedPermission = (
    permission: UserPermissions
  ): AccessPermissionActions[] => {
    const formattedPermission = userPermissions[permission]

    return formattedPermission ? [formattedPermission].flat() : []
  }
}

export default CabinetStore
