import React, { useEffect } from "react"
import { Path, useFieldArray } from "react-hook-form"
import { DocumentsBlockProps, UploadDocumentParams } from "./types"
import { getFormValue } from "../../../utils/service/form"
import { EditDocumentFields } from "../EditDocumentModal/types"
import { getVisibility } from "../../../utils/service/getVisibility"
import DocumentsBlock from "../DocumentsBlock"

function DocumentsBlockWithForm<T extends object>({
  form,
  name,
  documents,
  ...other
}: DocumentsBlockProps<T>) {
  const { fields, append, remove, update } = useFieldArray<T>({
    control: form.control,
    name: name as any,
  })

  const addFiles = (fileFields: UploadDocumentParams[]) => {
    fileFields.forEach((file) => {
      append(file as any)
    })
  }

  const changeFileName = (data: EditDocumentFields, key?: number) => {
    if (typeof key === "number") {
      const formValue = getFormValue(`${name}.${key}`, form)
      update(key, {
        ...formValue,
        name: data.name,
        tenant: data.tenant,
        landlord: data.landlord,
        visibility: getVisibility(data),
      } as any)
    }
  }

  useEffect(() => {
    form.setValue(name as Path<T>, (documents as any) || [])
  }, [documents])

  return (
    <DocumentsBlock
      // @ts-ignore
      documents={fields}
      addFiles={addFiles}
      onChange={changeFileName}
      onDelete={remove}
      {...other}
    />
  )
}

DocumentsBlockWithForm.defaultProps = {
  name: "documents",
}

export default DocumentsBlockWithForm
