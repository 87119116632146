import React from "react"
import { Box, Caption, Grid, Heading } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import Container from "../../../../../../../components/ui/Container"
import useApartmentStore from "../../../../../store"

function ApartmentInspectionsHeader() {
  const apartmentStore = useApartmentStore()
  const { inspectionsStore } = apartmentStore
  const formattedDate =
    inspectionsStore?.nextInspectionDate?.toFormat("dd MMMM")

  return (
    <Container>
      <Box pt={2}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Heading size="h3" weight={700}>
              Inspections
            </Heading>
            <Caption color="fourty" weight={500} size="xs">
              Every {inspectionsStore?.frequency} month
            </Caption>
          </Grid>
          <Grid item>
            {formattedDate && (
              <Caption size="s" weight={500}>
                <StyledAccent>Next:</StyledAccent> {formattedDate}
              </Caption>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default observer(ApartmentInspectionsHeader)

const StyledAccent = styled.span`
  color: ${({ theme }) => theme.palette.grey.fourty};
`
