import React from "react"
import styled from "@emotion/styled"
import { useToggle } from "kui-utils"
import {
  onInspectionModalOpen,
  useInspectionData,
  useInspectionIndexDB,
} from "kui-crm_actions"
import { PlusIcon } from "kui-icon"
import { Button } from "kui-basic"
import { InspectionTypes } from "kui-crm"
import { observer } from "mobx-react"
import InspectionsTab from "../../tabs/InspectionsTab"
import AppraisalsTab from "../../tabs/AppraisalsTab"
import ImpairmentsTab from "../../tabs/ImpairmentsTab"
import PageTabsPanel from "../../../../../../../components/ui/PageTabsPanel"
import useTabIndex from "../../../../../../../hooks/useTabIndex"
import ProtectedTabs from "../../../../../../../components/common/ProtectedTabs"
import { ProtectedTabParams } from "../../../../../../../components/common/ProtectedTabs/types"
import {
  inspectionCreationConstants,
  inspectionFieldsConstants,
} from "../../../../../../../utils/content/constants"
import useApartmentStore from "../../../../../store"
import InspectionCreationModal from "../InspectionCreationModal"

const tabs: ProtectedTabParams[] = [
  { label: "Inspections", permission: "objectInspectionsTab" },
  { label: "Impairments", permission: "objectImpairmentsTab" },
  { label: "Appraisals", permission: "objectAppraisalsTab" },
]

const paths = [
  `?tab=inspections&subtab=inspections`,
  `?tab=inspections&subtab=impairments`,
  `?tab=inspections&subtab=appraisals`,
]

const tabPanels = [<InspectionsTab />, <ImpairmentsTab />, <AppraisalsTab />]

const inspectionType = [undefined, "impairments", "appraisal"]

const InspectionsTabs = () => {
  const apartmentStore = useApartmentStore()
  const { isArchived } = apartmentStore
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const activeTab = useTabIndex(paths, "subtab")
  const type = inspectionType[Number(activeTab)] as InspectionTypes | undefined

  const onModalOpen = () => {
    onInspectionModalOpen(
      apartmentStore,
      inspectionFieldsConstants,
      handleModalOpen
    )
  }

  useInspectionData(apartmentStore)

  useInspectionIndexDB(
    inspectionCreationConstants,
    apartmentStore,
    handleModalOpen
  )

  return (
    <>
      <ProtectedTabs withSpacing tabs={tabs} tabPanels={tabPanels}>
        <StyledTabsPanel
          activeTab={Number(activeTab)}
          paths={paths}
          endComponent={
            <Button
              isCircle
              onClick={onModalOpen}
              size="xs"
              disabled={isArchived}
            >
              <PlusIcon width={10} height={10} />
            </Button>
          }
        />
      </ProtectedTabs>

      <InspectionCreationModal
        open={isModalOpen}
        handleClose={handleModalClose}
        variant={type}
      />
    </>
  )
}

export default observer(InspectionsTabs)

const StyledTabsPanel = styled(PageTabsPanel)`
  .KUI-TabsContainer {
    background: white;
    top: 121px;
    border: none;
  }
  .KUI-TabsPanelWrapper {
    align-items: center;
  }
`
