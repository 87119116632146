import React from "react"
import styled from "@emotion/styled"
import { Caption, Grid, theme } from "kui-basic"
import { observer } from "mobx-react"
import _ from "lodash"
import { Link, ModalProps } from "kui-crm"
import { useToggle } from "kui-utils"
import { onInspectionModalOpen } from "kui-crm_actions"
import {
  ApartmentInspectionRowStylesProps,
  ApartmentInspectionsRowProps,
} from "./types"
import { DynamicRoutes } from "../../../../../../../types/route"
import LinkOrAddButton from "../../../../../../../components/common/LinkOrAddButton"
import InspectionCreationModal from "../InspectionCreationModal"
import useApartmentStore from "../../../../../store"
import LinkButton from "../../../../../../../components/common/LinkButton"
import InspectionFeedbackStatus from "../../../../../../../components/common/InspectionFeedbackStatus"
import AppraisalTenantOfferStatus from "../AppraisalTenantOfferStatus"
import { inspectionFieldsConstants } from "../../../../../../../utils/content/constants"
import InspectionStatus from "../InspectionStatus"

function ApartmentInspectionRow(props: ApartmentInspectionsRowProps) {
  const { inspection } = props
  const apartmentStore = useApartmentStore()
  const { overviewStore, isArchived } = apartmentStore
  const [isHover, hover, blur] = useToggle()
  const withImpairment = isArchived
    ? !!inspection.impairmentsId
    : (inspection.type !== "appraisal" && inspection.type !== "impairments") ||
      !!inspection.impairmentsId
  const withAppraisal = isArchived
    ? !!inspection.appraisalId
    : (inspection.type !== "appraisal" && inspection.type !== "impairments") ||
      !!inspection.appraisalId
  const inspectionLink = DynamicRoutes.inspection(
    overviewStore.id!,
    inspection.id
  )
  const impairmentsLink =
    !!inspection.impairmentsId &&
    DynamicRoutes.inspection(overviewStore.id!, inspection.impairmentsId)
  const appraisalLink =
    !!inspection.appraisalId &&
    DynamicRoutes.inspection(overviewStore.id!, inspection.appraisalId)
  const basedOnLink =
    !!inspection.basedOn?.id &&
    DynamicRoutes.inspection(overviewStore.id!, inspection.basedOn.id)
  const basedOnParams = {
    id: inspection.id,
    date: inspection.date,
    type: inspection.type,
  }

  const onInspectionCreateClick = () => {
    onInspectionModalOpen(apartmentStore, inspectionFieldsConstants)
  }

  return (
    <StyledRow
      isHover={isHover}
      container
      alignItems="center"
      justify="space-between"
    >
      <Link
        href={inspectionLink}
        onMouseEnter={hover}
        onMouseOver={hover}
        onMouseOut={blur}
      >
        <StyledContentWrapper item container alignItems="center">
          <StyledDate size="s">
            {inspection.date?.toFormat("dd.MM.yyyy") || "-"}
          </StyledDate>
          <InspectionFeedbackStatus
            tenantFeedback={inspection.tenantFeedback}
            landlordFeedback={inspection.landlordFeedback}
          />
          <InspectionStatus statusParams={inspection.statusParams} />
          <AppraisalTenantOfferStatus offer={inspection.tenantOfferStatus} />
          <Caption size="s" weight={500}>
            {_.capitalize(inspection.type)}
          </Caption>
        </StyledContentWrapper>
      </Link>
      <StyledLinksWrapper item container alignItems="center">
        <LinkOrAddButton
          isDisplayed={withImpairment}
          label="Impairments"
          link={impairmentsLink}
          onClick={onInspectionCreateClick}
          viewPermission="objectImpairmentsTab"
          createPermission="objectImpairmentsCreate"
          renderModal={(modalProps: ModalProps) => (
            <InspectionCreationModal
              variant="impairments"
              basedOn={basedOnParams}
              {...modalProps}
            />
          )}
        />
        <LinkOrAddButton
          isDisplayed={withAppraisal}
          label="Appraisal"
          link={appraisalLink}
          onClick={onInspectionCreateClick}
          viewPermission="objectAppraisalsTab"
          createPermission="objectAppraisalCreate"
          renderModal={(modalProps: ModalProps) => (
            <InspectionCreationModal
              variant="appraisal"
              basedOn={basedOnParams}
              {...modalProps}
            />
          )}
        />
        {!!inspection.basedOn?.id && (
          <StyledBasedOnLink fullWidth href={basedOnLink! || ""}>
            {_.capitalize(inspection.basedOn.type)}
          </StyledBasedOnLink>
        )}
      </StyledLinksWrapper>
    </StyledRow>
  )
}

export default observer(ApartmentInspectionRow)

const StyledDate = styled(Caption)`
  width: 77px;
  margin-right: 24px;
`

const StyledRow = styled(Grid)<ApartmentInspectionRowStylesProps>`
  background: white;
  border-radius: 12px;
  flex-wrap: nowrap;
  transition: all ease-out 0.3s;
  box-shadow: ${({ isHover }) => (isHover ? theme.shadows.shadow1 : "none")};
  min-height: 48px;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

const StyledContentWrapper = styled(Grid)`
  padding: 8px 0 8px 16px;
`

const StyledLinksWrapper = styled(Grid)`
  width: auto;
  flex-wrap: nowrap;
  padding: 8px 16px 8px 0;
  > div:not(:last-of-type) {
    margin-right: 16px;
  }
`

const StyledBasedOnLink = styled(LinkButton)`
  width: 200px;
`
