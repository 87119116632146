import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Caption, Button, Box, Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { EditIcon, TrashIcon } from "kui-icon"
import { UserNoteProps } from "./types"
import NoteEditingModal from "../NoteEditingModal"
import CommentBillet from "../CommentBillet"
import useStore from "../../../hooks/useStore"

function Note({ note }: UserNoteProps) {
  const { cabinetStore } = useStore()
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()

  return (
    <Box mb={2} data-testid={`note_${note.id}`}>
      <CommentBillet
        date={note.createdAt}
        name={note.createdBy?.fullName}
        endComponent={
          note.createdBy?.id === cabinetStore.userId && (
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  isCircle
                  data-testid="note_edit_button"
                  size="xs"
                  variant="transparent"
                  onClick={handleModalOpen}
                >
                  <StyledIcon />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  isCircle
                  data-testid="note_delete_button"
                  size="xs"
                  variant="transparent"
                  onClick={note.delete}
                >
                  <StyledTrashIcon />
                </Button>
              </Grid>
            </Grid>
          )
        }
      >
        <StyledContent size="s">{note.text}</StyledContent>
      </CommentBillet>
      <NoteEditingModal
        open={isModalOpen}
        handleClose={handleModalClose}
        note={note}
      />
    </Box>
  )
}

export default observer(Note)

const StyledContent = styled(Caption)`
  margin: 0;
  white-space: pre-wrap;
`

const StyledIcon = styled(EditIcon)`
  width: 16px;
  height: 16px;
  path {
    fill: ${({ theme }) => theme.palette.grey.fourty};
  }
`

const StyledTrashIcon = StyledIcon.withComponent(TrashIcon)
