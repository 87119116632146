import React from "react"
import { observer } from "mobx-react"
import useRegistriesStore from "../../../store"
import PaginationPage from "../../../../../components/common/PaginationPage"
import SortingTable from "../../../../../components/common/SortingTable"
import appraisalsHeadCells from "./content"
import TenantAppraisalRegistryRow from "./TenantAppraisalRegistryRow"
import TenantAppraisalsRegistryStore from "../../../store/variants/TenantAppraisalsRegistryStore"

const TenantAppraisalsRegistryTable = () => {
  const { activeRegistryStore } = useRegistriesStore()

  if (!activeRegistryStore) return null

  const { appraisals } = activeRegistryStore as TenantAppraisalsRegistryStore

  return (
    <PaginationPage store={activeRegistryStore}>
      <SortingTable
        headParams={appraisalsHeadCells}
        store={activeRegistryStore}
        offset={132}
      >
        {appraisals?.map((appraisal, key) => (
          <TenantAppraisalRegistryRow
            key={appraisal.id}
            appraisal={appraisal}
            isDark={key % 2 !== 0}
          />
        ))}
      </SortingTable>
    </PaginationPage>
  )
}

export default observer(TenantAppraisalsRegistryTable)
