import { makeAutoObservable } from "mobx"
import { ImageModel } from "kui-crm/types"
import { ImageParams, ImageStoreInterface } from "../../types/store/image"

class ImageStore implements ImageStoreInterface {
  id: number

  name: string

  url: string

  smallImageUrl: string

  mediumImageUrl: string

  largeImageUrl: string

  originalImageUrl: string

  size: number | null

  constructor(image: ImageParams) {
    this.id = image.id
    this.name = image.name ?? ""
    this.url = image.url ?? ""
    this.size = image.size ?? null
    this.smallImageUrl = image.smallImageUrl ?? ""
    this.largeImageUrl = image.largeImageUrl ?? ""
    this.mediumImageUrl = image.largeImageUrl ?? ""
    this.originalImageUrl = image.originalImageUrl ?? ""

    makeAutoObservable(this)
  }

  delete = async () => {}

  get extension() {
    return this.name.split(".")?.pop()?.toUpperCase() || ""
  }

  get title() {
    return this.name.split(".")?.[0]
  }

  static initFromImageModel = (image: ImageModel) =>
    new ImageStore({
      id: image.id,
      name: image.image_name,
      url: image.image_url || image.image_large_url,
      smallImageUrl: image.image_small_url,
      largeImageUrl: image.image_large_url,
      originalImageUrl: image.image_url,
    })
}

export default ImageStore
