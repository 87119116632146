import { addToArrayByCondition } from "kui-utils"
import { InputParams, LinkField, InspectionTypes } from "kui-crm"
import { ClientRoleValues } from "../../../../../utils/content/listsOptions"
import InspectionTypeFiled from "../InspectionTypeFiled"
import isContractClosed from "../../../../../utils/service/isCotractClosed"
import TransferInspectionTypeFiled from "../TransferInspectionTypeFiled"
import InspectionsField from "../InspectionsField"

const getInspectionCommonStepFields = (
  withType?: boolean,
  type?: InspectionTypes,
  fields?: any,
  shouldUploadContract?: boolean,
  withBasedOnField?: boolean
): InputParams<any>[] => [
  { label: "Inspection date", name: "date", variant: "date", isRequired: true },
  ...addToArrayByCondition(withType, {
    name: "type",
    variant: "custom",
    CustomInput: InspectionTypeFiled,
  }),
  ...addToArrayByCondition(type === "transfer", {
    name: "transferType",
    variant: "custom",
    CustomInput: TransferInspectionTypeFiled,
  }),
  ...addToArrayByCondition(withBasedOnField, {
    name: "basedOn",
    variant: "custom",
    CustomInput: InspectionsField,
    forType: type,
  }),
  ...addToArrayByCondition(type === "appraisal" && fields?.basedOn?.id, {
    label: "Client",
    name: "clientRole",
    variant: "select",
    options: ClientRoleValues,
    disabled: isContractClosed(fields?.rentalContractStatus),
    value: isContractClosed(fields?.rentalContractStatus)
      ? "landlord"
      : undefined,
    isRequired: true,
  }),
  ...addToArrayByCondition(shouldUploadContract, {
    label: "Signed contract file",
    name: "contractFile",
    variant: "file",
    isRequired: true,
  }),
  {
    label: "Yandex disk link",
    name: "dropboxLink",
    variant: "custom",
    CustomInput: LinkField,
  },
]

export default getInspectionCommonStepFields
