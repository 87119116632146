import * as React from "react"
import { UserRole } from "../../types/api/cabinet"
import AccountantDashboard from "./variants/AccountantDashboard"
import HousingInspectorDashboard from "./variants/HousingInspectorDashboard"
import HousingManagerDashboard from "./variants/HousingManagerDashboard"
import AdminDashboard from "./variants/AdminDashboard"
import SalesManagerDashboard from "./variants/SalesManagerDashboard"
import ConsultantDashboard from "./variants/ConsultantDashboard"
import AnalystDashboard from "./variants/AnalystDashboard"

const getDashboardPage = (role: UserRole) => {
  switch (role) {
    case "admin":
      return <AdminDashboard />
    case "accountant":
      return <AccountantDashboard />
    case "housing_inspector":
      return <HousingInspectorDashboard />
    case "housing_manager":
      return <HousingManagerDashboard />
    case "sales_manager":
      return <SalesManagerDashboard />
    case "consultant":
      return <ConsultantDashboard />
    case "analyst":
      return <AnalystDashboard />
    default:
      return null
  }
}

export { getDashboardPage }
