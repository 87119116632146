import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { LoaderState, useAutocomplete } from "kui-crm"
import { observer } from "mobx-react"
import InputWithAutocomplete from "../../../../ui/InputWithAutocomplete"
import { CompanyBankAccountFields } from "../../../../entityInputs/CompanyBankAccountAutocomplete/types"
import AutocompleteOption from "../../../../common/AutocompleteOption"
import { InspectionsFieldProps } from "./types"
import InspectionsListStore from "../../../../../store/lites/InspectionsListStore"
import { InspectionParams } from "../../../../../types/store/inspections"
import useApartmentLiteStore from "../../../../../store/shared/apartment"

const InspectionsField = (props: InspectionsFieldProps) => {
  const { form, name, forType, ...otherProps } = props
  const [isOpen, setIsOpen] = useState(false)
  const inspectionsStoreRef = useRef(new InspectionsListStore())
  const { overviewStore } = useApartmentLiteStore()
  const apartmentId = overviewStore.id
  const singleOption = {
    id: 0,
    type: null,
    date: null,
    name: `Single ${forType}`,
  }

  const { inspections, fetchAll, filter, paginator, loader } =
    inspectionsStoreRef.current
  const { isLoading } = loader
  const options = useMemo(
    () => [...inspections, singleOption],
    [inspections, forType]
  )

  const fetchById = useCallback(async () => {
    if (apartmentId) {
      if (forType === "appraisal") {
        filter.updateFilterQuery(`&can_add_appraisal=true`)
      } else if (forType === "impairments") {
        filter.updateFilterQuery(`&can_add_impairments=true`)
      }
      fetchAll(apartmentId)
    }
  }, [apartmentId])

  const { onSearchItem } = useAutocomplete(
    filter,
    paginator,
    fetchById,
    true,
    undefined,
    undefined,
    [apartmentId]
  )

  const onSelectItem = (item: InspectionParams | null) => {
    form.setValue(`${name}.id`, item?.id)
    form.setValue(`${name}.name`, item?.name, { shouldValidate: true })
  }

  useEffect(() => {
    if (!isOpen && inspections.length === 1) {
      onSelectItem(options[0])
    }
  }, [isOpen, inspections.length])

  return (
    <>
      <InputWithAutocomplete
        renderOption={(option: CompanyBankAccountFields) => (
          <AutocompleteOption label={option.name} />
        )}
        isLoading={isLoading}
        options={options}
        label="Inspection"
        form={form}
        name={`${name}.name`}
        hideName={`${name}.id`}
        onSelectItem={onSelectItem}
        onSearchItem={onSearchItem}
        onOpen={setIsOpen}
        isRequired
        {...otherProps}
      />
      <LoaderState loader={loader} onlyError />
    </>
  )
}

export default observer(InspectionsField)
