import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { Loading } from "kui-complex"
import {
  getKPICards,
  getRentalContractsCards,
  getServiceContractsCards,
} from "./content"
import useDashboardStore from "../../../store"
import DashboardCardsGroup from "../../../components/common/DashboardCardsGroup"

const AnalystDashboardCards = () => {
  const { analystDashboard } = useDashboardStore()
  const { actionLoader, loader } = analystDashboard

  if (actionLoader.isLoading || loader.isLoading)
    return <Loading height="80vh" />

  const serviceContractsCards = getServiceContractsCards(analystDashboard)
  const rentalContractsCards = getRentalContractsCards(analystDashboard)
  const kpiCards = getKPICards(analystDashboard)

  return (
    <Grid container spacing={4}>
      <DashboardCardsGroup cards={serviceContractsCards} />
      <DashboardCardsGroup cards={rentalContractsCards} />
      <DashboardCardsGroup cards={kpiCards} />
    </Grid>
  )
}

export default observer(AnalystDashboardCards)
