import React from "react"
import _ from "lodash"
import { Grid, IconButton } from "kui-basic"
import { CloseIcon, UpdateIcon } from "kui-icon"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { RejectedInspectionRowProps } from "./types"
import RowWithApartment from "../../../common/RowWithApartment"
import { DynamicRoutes } from "../../../../../../types/route"
import InspectionFeedbackIndicator from "../../../../../../components/common/InspectionFeedbackStatus/InspectionFeedbackIndicator"
import inspectionFeedbackLabel from "../../../../../../components/common/InspectionFeedbackStatus/content"
import { InspectionSignParams } from "../../../../../ApartmentPage/types/store/apartmentInspections"

const RejectedInspectionRow = (props: RejectedInspectionRowProps) => {
  const { inspection, isArchive } = props
  const { id, apartment, type, rejectedComment } = inspection
  const label = _.capitalize(`${type} appraisal`)
  const icon = isArchive ? (
    <UpdateIcon width={18} height={18} />
  ) : (
    <CloseIcon width={12} height={12} />
  )
  const link = DynamicRoutes.inspection(apartment.id, id)
  const feedback: InspectionSignParams = {
    status: "rejected",
    comment: rejectedComment,
  }

  const handleClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (isArchive) inspection.restoreInspection()
    else inspection.archiveInspection()
  }

  return (
    <RowWithApartment label={label} apartment={apartment} link={link}>
      <StyledWrapper container>
        <InspectionFeedbackIndicator
          feedback={feedback}
          labels={inspectionFeedbackLabel}
        />
        <StyledButton onClick={handleClick}>{icon}</StyledButton>
      </StyledWrapper>

      <LoaderState loader={inspection.loader} />
    </RowWithApartment>
  )
}

export default observer(RejectedInspectionRow)

const StyledButton = styled(IconButton)`
  margin-left: 8px;
`

const StyledWrapper = styled(Grid)`
  width: fit-content;
`
